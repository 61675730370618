import React from "react"
import GoogleMapReact from "google-map-react"
import Marker from "./Marker"
import mapStyles from "../mapStyle.js"
import styled from "styled-components"
import hyllie from "../../../images/svg/hyllie.svg"
import triangeln from "../../../images/svg/triangeln.svg"
import kastrup from "../../../images/svg/kastrup.svg"
import limhamn from "../../../images/svg/limhamn.svg"
import kalcium from "../../../images/svg/ca-logo-white.svg"

const Map = () => {
  const defaultProps = {
    center: {
      lat: 55.565995,
      lng: 12.944205,
    },
    zoom: 13,
    mapStyles: mapStyles,
    styles: mapStyles,
  }
  return (
    <MapStyle id="map">
      <GoogleMapReact
        // old key
        // bootstrapURLKeys={{ key: "AIzaSyCBA604xkIr5Rr81BEH47TeSiKB-XVOVXE" }}
        // new
        bootstrapURLKeys={{ key: "AIzaSyAq7TBqwagV6oUAV8KQ_UjQJcxsWu7vi0w" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={{
          fullscreenControl: false,
          styles: defaultProps.styles,
          streetViewControl: false,
          scaleControl: true,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          disableDefaultUI: true,
          minZoom: defaultProps.zoom - 1000,
          maxZoom: defaultProps.zoom + 1000,
        }}
      >
        <PermanentMarker lat={55.565995} lng={12.944205}>
          <img src={kalcium} alt="kalcium icon" />
          <div className="border"></div>
        </PermanentMarker>
        <Marker
          lat={55.566141}
          lng={12.883485}
          place="Kastrup"
          time="20"
          icon={kastrup}
        />
        <Marker
          lat={55.573862}
          lng={12.916591}
          place="Limhamn"
          time="9"
          icon={limhamn}
        />
        <Marker
          lat={55.564253}
          lng={12.973926}
          place="Hyllie"
          time="7"
          icon={hyllie}
        />
        <Marker
          lat={55.595507}
          lng={13.001671}
          place="Triangeln"
          time="8"
          icon={triangeln}
        />
      </GoogleMapReact>
    </MapStyle>
  )
}

const PermanentMarker = styled.div`
  position: absolute;
  top: -30px;
  left: -30px;
  .border {
    width: 100%;
    height: 100%;
    background: #000;
  }

  @media only screen and (max-width: 800px) {
    transform:scale(0.6);
  }
`

const MapStyle = styled.div`
  position: relative;
  height: 800px;
  width: 100%;
  filter: brightness(90%);

  .filterContainer {
    position: absolute;
    z-index: 9999;
    display: flex;
    bottom: 20px;
    left: 20px;
  }

  @media only screen and (max-width: 800px) {
    height: 400px;
    .filterContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }
`

export default Map
