import React from "react"
import styled from "styled-components"

const Button = ( {icon, title, link} ) => {
  return (
    <Container href={link} target="_blank">
      {icon && <img src={icon} alt="icon" />}
      <p id="title">{title}</p>
    </Container>
  )
}

const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  padding: 20px;
  margin-right: 15px;
  margin-bottom: 15px;
  min-width: 200px;
  flex: 1;
  color: #fff;
  text-decoration: none;
  transition: ease 0.3s;

  p, img {
    margin-bottom: 0;
  }
  #title {
    margin: 0;
  }
  img {
    margin-right: 10px;
    transition: ease 0.3s;
  }

  &:hover {
    background: rgba(0,0,0,0.3);
    img {
      // transform: scale(1.2);
    }
  }
`

export default Button
