import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "./Button"
import intIcon from "../../images/svg/interiorChoices.svg"
import houseIcon from "../../images/svg/house.svg"
import { StaticQuery, graphql, Link } from "gatsby"

const ReversedTextBlock = props => {
  const { body } = props.text
  return (
    <StaticQuery
      query={graphql`
        query CTA {
          lagenheter: file(relativePath: { eq: "exterior/Exterior.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          radhus: file(relativePath: { eq: "exterior/Exterior3.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Container className={props.theClass}>
          <Img className="backgroundImage" fluid={props.image} />
          <CTA className="hider">
            <Link to="lagenheter" className="cta lg">
              <span className="total">61</span>
              <span className="short">Lä</span>
              <p className="type">Lägenheter</p>
              <Img fluid={data.lagenheter.childImageSharp.fluid}></Img>
            </Link>
            <Link to="radhus" className="cta ra">
              <span className="total">4</span>
              <span className="short">Ra</span>
              <p className="type">Radhus</p>
              <Img fluid={data.radhus.childImageSharp.fluid}></Img>
            </Link>
            <div className="line"></div>
          </CTA>
          <Text className="overlay-green">
            <MDXRenderer>{body}</MDXRenderer>
            <div className="buttonContainer hider">
              <Button link="https://cdn.sightline.se/1584a7c4a62afa81/psw/Elinegard/" icon={houseIcon} title="Hitta ditt boende" />
              <Button link="https://showroom.homemaker.io/sales-start/UA7QciKx" icon={intIcon} title="Tillval" />
            </div>
          </Text>
        </Container>
      )}
    />
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 35vw 220px calc(100vw - 35vw - 220px);
  grid-template-rows: 1fr;
  border-top: 1px solid #fff;

  .backgroundImage {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  @media only screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    grid-template-rows: 440px 1fr;

    .backgroundImage {
      grid-column: 1;
      grid-row: 1 / 3;
    }

    &.hideCTA .backgroundImage {
      grid-row: 1 !important;
    }
  }
  @media only screen and (max-width: 800px) {
    grid-template-rows: calc(50vw + 40px) 1fr;
    .backgroundImage {
      filter: brightness(0.9);
    }
  }
`

const CTA = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 17.5vw 17.5 260px 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  .line {
    grid-column: 2;
    grid-row: 1 / 5;
    border-right: 1px solid #fff;
    z-index: 9;
    pointer-events: none;
  }

  .cta {
    position: relative;
    width: 220px;
    height: 220px;
    background-color: #000;
    z-index: 9;
    overflow: hidden;
    border: 1px solid #fff;

    .total,
    .short,
    .type {
      position: absolute;
      z-index: 99;
      color: #fff;
    }

    .total {
      font-family: 'HelveticaNeueRoman';
      top: 10px;
      left: 10px;
      font-size: 25px;
      font-weight: 900;
    }

    .short {
      top: calc(50% + 25px);
      left: 50%;
      font-size: 120px;
      font-weight: 900;
      transform: translate(-50%, -50%);
      font-family: 'HelveticaNeue';
      font-weight:  500;
      letter-spacing: 4px;
    }

    .type {
      font-family: 'HelveticaNeue';
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      margin-bottom: 0px;
    }

    .gatsby-image-wrapper {
      transition: ease 0.3s;
    }
    
    &:hover {
      .gatsby-image-wrapper {
        filter: brightness(50%);
      }
    }
  }

  .lg {
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
  }

  .ra {
    position: relative;
    grid-column: 3;
    grid-row: 3;
  }

  .gatsby-image-wrapper {
    position: absolute;
    margin-bottom: none;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right;
    opacity: 0.8;
  }

  @media only screen and (max-width: 1700px) {
    grid-template-columns: 80px 17.5vw 260px 1fr;
  }

  @media only screen and (max-width: 1300px) {
    .ra {
      grid-column: 2;
      grid-row: 3;
      justify-self: end;
      top: 20px;
    }
  }

  @media only screen and (max-width: 1080px) {
    display: flex;
    justify-content: center;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 2rem;

    .lg {
      width: 50%;
      height: 340px;
      margin: 40px 20px 40px 0px;
    }
    .ra {
      width: 50%;
      height: 340px;
      margin: 40px 40px 40px 20px;
      top: 0px;
    }

    .line {
      display: none;
    }
  }

  @media only screen and (max-width: 800px) {
    .lg {
      width: calc(50vw - 2rem);
      height: calc(50vw - 2rem);
      margin: 20px 10px 20px 0px;
    }
    .ra {
      width: calc(50vw - 2rem);
      height: calc(50vw - 2rem);
      margin: 20px 0px 20px 10px;
    }
    .cta {
      .short {
        top: 50%;
        font-size: 50px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 1rem;

    .lg,
    .ra {
      width: calc(50vw - 1rem);
      height: calc(50vw - 1rem);
    }
  }
`

const Text = styled.div`
  position: relative;
  padding: 2rem;
  color: #fff;
  grid-column: 3;
  grid-row: 1 / 3;
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  justify-self: end;

  @media only screen and (min-width: 801px) {
    padding: 7rem 2rem 4rem;
  }

  h3{
    font-family: HelveticaNeueRoman;
    font-size: 20px;
    font-weight: 500;
    margin-top:10px;
    margin-bottom:5px;
  }

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  h2 {
    display: flow-root !important;
  }

  @media only screen and (max-width: 1080px) {
    grid-column: 1;
    grid-row: 2 / 3;
    justify-self: unset;
    margin: 0 auto;
    width: 100%;
  }

  @media only screen and (max-width: 500px) {
    padding: 2rem 1rem;
  }
`

export default ReversedTextBlock
