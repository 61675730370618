import React, { useState } from 'react';
import styled from 'styled-components';
import permanentMarker from '../markerIcon.svg'
const Marker = (props) => {

  const { name, id, category, key, time, place, icon } = props;
  const [showingInfoWindow, setshowingInfoWindow] = useState(false);

  const onMarkerClick = (event) => showingInfoWindow ? setshowingInfoWindow(false) : setshowingInfoWindow(true)

  const InfoWindow = (props) => {
    const { place, icon} = props
    if (showingInfoWindow) {
      return (
        <div className="infobox">
          <h4>{place}</h4>
          <img src={icon} alt="icon"/>
        </div>
      )
    } else {
      return null
    }
  }
  return (
    <Mark
      role="button"
      tabIndex={0}
      onMouseEnter={onMarkerClick}
      onMouseLeave={onMarkerClick}
      onKeyDown={onMarkerClick}
    >
      <div key={key} id={id} title={name} category={category} className={`pin`}>
      {!showingInfoWindow ? 
      <>
        <h4>{place}</h4>
        <p>{time}</p>
        <span>min</span>
      </>
      : <></> 
      }
      <InfoWindow
        place={place}
        icon={icon}
        role="exit"
      />
      </div>
    </Mark>
  );
};

const Mark = styled.div`
.pin {
  z-index: 99999999;
  width: 100px;
  height: 100px;
  background: rgba(44,27,14,0.7);
  position: absolute;
  left: 100%;
  top: 100%;
  margin: -20px 0 0 -20px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: ease-in-out 0.3s;
  outline: 0;
  * {
    pointer-events: none;
  }
  h4 {
    margin-bottom: 0.8rem;
    font-weight:500;
  }
  
  p {
    margin-bottom: 0.8rem;
    font-weight:900;
    font-size: 50px;
  }

  &:hover {
    background: rgba(44,27,14,0.8);
  }
}

.filterContainer {
  position: absolute;
  z-index: 999;
  background: rgba(255, 255, 255, 0.7);
  padding: 30px;
  width: 80%;
  top: 5%;
  right: 10%;
  font-size: 28px;
  &__title {
    font-family: "century";
    font-size: 22px;
    margin-bottom: 4px;
  }
  &__filterButton {
    cursor: pointer;
    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 5px;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  .checked .checkbox {
    background-color: #39506d;
  }
  .checkbox {
    width: 15px;
    height: 15px;
    display: block;
    background-color: #fff;
    margin-right: 10px;
    border: 1px solid #39506d;
    pointer-events: none;
    transition: ease 0.3s;
    &:hover {
      background-color: #39506d;
    }
  }
}

.infobox {
  position:absolute;
  top:0;
  left: 0;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  line-height: 14px;
  cursor: default;
  pointer-events: none;

  * {
    pointer-events: none;
  }
  img {
    margin-bottom: 0;
    position: relative;
    top:-10px;
  }
}

span.exit {
  position: absolute;
  right: 5px;
  top: 5px;
  line-height: 12px;
}

div#permanent {
  background-color: unset;
  pointer-events: none;
  width: 61px;
  height: 61px;
  background-size: contain;
  border-radius: 0;
  background-image: url('${permanentMarker}');
  background-repeat: no-repeat;
  position: relative;
  top:-8px;
  left:2px;

  & + .infobox {
    display:none;
  }
  &::after {
    display:none;
  }
}

@media only screen and (max-width: 800px) {
  .pin {
    transform: scale(0.5);
    display: none;
  }
}
@media only screen and (min-width: 740px) {
  .filterContainer {
    max-width: 350px;
    top: 0;
    right: 0;
    &__title {
      font-size: 25px;
    }
  }
}
`

export default Marker;