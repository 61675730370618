import React from "react"
import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Hero from '../components/Hero'
import { graphql } from 'gatsby'
import TextBlock from '../components/reusableUi/TextBlock'
import ReversedTextBlock from '../components/reusableUi/ReversedTextBlock'
import FormSections from '../components/reusableUi/FormSections'
import icon from '../images/svg/ca-icon.svg'
import Map from '../components/maps/travel/Map'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Hem" description="Kalcium är inte bara ett livsnödvändigt grundämne. Det är också ett nytt välplanerat och trivsamt bostadsrättsprojekt med 61 lägenheter och tre radhus på bästa läge precis intill det majestätiska naturreservatet Kalkbrottet i den nya stadsdelen Elinegård."/>
      <Hero 
      iconImage={icon}
      heroClass="frontpageHero"
      />
      <TextBlock 
        image={data.exteriorImage.childImageSharp.fluid}
        imageMap={data.oversikt.childImageSharp.fluid}
        text={data.exteriorText}
        theClass="fixedBG"
        showMapImage={true}
      /> 
      <ReversedTextBlock 
        image={data.leaf.childImageSharp.fluid}
        text={data.linksToPages}
        theClass="showCTA"
        /> 
        <Map />
      <ReversedTextBlock 
        image={data.innergardImage.childImageSharp.fluid}
        text={data.innergard}
        theClass="hideCTA"
      /> 
      <TextBlock 
        image={data.terrassImage.childImageSharp.fluid}
        text={data.terrass}
        theClass="hideCTA"
      /> 
      <FormSections />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
query {
  exteriorText: mdx(frontmatter: {title: {eq: "Ett boende som uppfyller alla dagsbehov"}}) {
    frontmatter {
      title
    }
    body
  }
  
  exteriorImage: file(relativePath: {eq: "exterior/Exterior.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  oversikt: file(relativePath: {eq: "oversikt.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  background: file(relativePath: {eq: "heroImages/fp-hero.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  
  linksToPages: mdx(frontmatter: {title: {eq: "Boende för många behov"}}) {
    frontmatter {
      title
    }
    body
  }

  leaf: file(relativePath: {eq: "leaf_bg.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  
  innergard: mdx(frontmatter: {title: {eq: "Lummig innergård"}}) {
    frontmatter {
      title
    }
    body
  }

  innergardImage: file(relativePath: {eq: "exterior/Innergard.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  terrass: mdx(frontmatter: {title: {eq: "Ska vi kolla solnedgången?"}}) {
    frontmatter {
      title
    }
    body
  }

  terrassImage: file(relativePath: {eq: "exterior/Terrass.jpg"}) {
    childImageSharp {
      fluid(maxWidth: 2000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;
