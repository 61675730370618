import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Button from "./Button"
import intIcon from "../../images/svg/interiorChoices.svg"
import houseIcon from "../../images/svg/house.svg"

const TextBlock = props => {
  const { body } = props.text
  return (
    <Container className={props.theClass}>
      <Img className="backgroundImage fixed" fluid={props.image} />
      <Text className="text">
        <MDXRenderer>{body}</MDXRenderer>
        <div className="buttonContainer hider">
          <Button link="https://cdn.sightline.se/1584a7c4a62afa81/psw/Elinegard/" icon={houseIcon} title="Hitta ditt boende" />
          <Button link="https://showroom.homemaker.io/sales-start/UA7QciKx" icon={intIcon} title="Tillval" />
        </div>
        {props.showMapImage && 
         <Img style={{ marginTop: "18px"}} fluid={props.imageMap} />
        }
      </Text>
      <div className="overlay" />
    </Container>
  )
}

const Container = styled.section`
  display: grid;
  grid-template-columns: 35vw 1fr;
  grid-template-rows: 1fr;
  border-top: 1px solid #fff;
  overflow-x: hidden;
  
  .backgroundImage {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .overlay {
    grid-column: 2;
    grid-row: 1;
    position: relative;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  @media only screen and (max-width: 1080px) {
    grid-template-columns: 50vw;
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template-rows: 400px min-content;
    
    .overlay {
      grid-column: 1;
    }
    
    .backgroundImage {
      grid-column: 1;
    }

    h2 {
      font-size: 40px !important;
      line-height: 40px !important;
    }
  }
`
const Text = styled.div`
  position: relative;
  padding: 2rem;
  @media (min-width: 801px) {
    padding: 7rem 2rem 4rem;
  }
  color: #fff;
  grid-column: 1;
  grid-row: 1 / 3;
  position: relative;
  z-index: 9;
  background-color: rgba(44, 27, 14, 0.7);
  border-right: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  h3{
    font-family: HelveticaNeueRoman;
    font-size: 20px;
    font-weight: 500;
    margin-bottom:5px;
    margin-top:10px;
  }

  @media only screen and (max-width: 800px) {
    grid-row: 2;
    border-right:0;
  }

  @media only screen and (max-width: 500px) {
    padding:2rem 1rem;
  }
`

export default TextBlock
